import React from "react";
import yg from "../../assets/img/company/yg_logo.svg";
// import "../../assets/styles/Socialspace.css";
import YeogeuCard from "../../components/yeogeu/YeogeuCard";

// 이미지 파일 경로 배열
const cardImages = [
  require("../../assets/img/yeogeu/1.png"),
  require("../../assets/img/yeogeu/2.png"),
  require("../../assets/img/yeogeu/3.png"),
  require("../../assets/img/yeogeu/4.png"),
  require("../../assets/img/yeogeu/5.png"),
  require("../../assets/img/yeogeu/6.png"),
];

function Yeogeu() {
  const cardTexts = [
    "외경",
    "1층 로비",
    "1층 미팅룸",
    "1층 세미나실",
    "3,4층 공유 오피스",
    "4층 주방",
  ];

  return (
    <div className="2xl:w-[60%] w-[90%] mx-auto md:mt-10 mt-5 flex flex-col md:gap-14 gap-8 leading-7">
      {/* 1 */}
      <div className="flex flex-col items-center w-full p-3 rounded-lg md:p-5 md:flex-row bg-boxbg">
        <div className="flex items-center justify-center w-full md:w-1/6">
          <div className="flex items-center justify-center bg-white rounded-full md:w-20 w-14 aspect-square">
            <img src={yg} alt="" className="w-12 h-1w-12" />
          </div>
        </div>
        <div className="flex flex-col w-full gap-1 pr-0 md:w-5/6 md:gap-2 md:pr-6">
          <p className="mt-2 text-base font-semibold text-center md:text-left md:text-xl md:mt-0">
            사회실험공간 여그
          </p>
          <p className="text-sm md:text-base">
            사회실험공간 여그는 2019년도 코끼리협동조합에서 설립한 공간입니다.
            <br />
            메이커와 지역 청년을 위한 공간으로 메이커스페이스, 공유 오피스,
            스튜디오 등이 갖춰져 있습니다.
          </p>
        </div>
      </div>
      {/* 2 */}
      <div>
        <p className="mb-2 text-lg font-semibold md:mb-4 md:text-2xl">
          여그 공간 사진
        </p>

        <div className="flex flex-col grid-cols-2 grid-rows-3 gap-2 md:grid md:gap-4">
          {cardTexts.map((text, index) => (
            <YeogeuCard key={index} text={text} image={cardImages[index]} />
          ))}
        </div>
      </div>
    </div>
  );
}

export default Yeogeu;
