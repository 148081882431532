// Product
import React from "react";
import { Outlet } from "react-router-dom";
import Navbar from "../navbar/Navbar";
import Footer from "../footer/Footer";
import styled from "styled-components";

const LayoutContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
`;

const MainContent = styled.div`
  flex: 1;
`;

function Layout() {
  return (
    <LayoutContainer>
      <Navbar />

      <MainContent>
        <Outlet />
      </MainContent>

      <Footer />
    </LayoutContainer>
  );
}

export default Layout;
