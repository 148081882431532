import React from "react";
import { Link } from "react-router-dom";
import kakao from "../../../assets/img/carousel/story/kakao.png";
import link from "../../../assets/img/carousel/story/link.png";

import complete from "../../../assets/img/story/bookVillage/complete.jpg";
import meeting from "../../../assets/img/story/bookVillage/meeting.jpg";
import location from "../../../assets/img/story/bookVillage/location.jpg";
import make1 from "../../../assets/img/story/bookVillage/make1.jpg";
import make2 from "../../../assets/img/story/bookVillage/make2.JPG";
import make3 from "../../../assets/img/story/bookVillage/make3.JPG";
import make4 from "../../../assets/img/story/bookVillage/make4.JPG";
import make5 from "../../../assets/img/story/bookVillage/make5.JPG";
import make6 from "../../../assets/img/story/bookVillage/make6.jpg";
import make7 from "../../../assets/img/story/bookVillage/make7.jpg";

function BookVillage() {
  return (
    <div className="2xl:w-[60%] w-[90%] mx-auto md:mt-10 mt-5 flex flex-col gap-32">
      {/* 상단 */}
      <div className="flex flex-col items-center gap-1 md:gap-3">
        <div className="flex text-sm md:text-base lg:text-lg">
          <p>제작</p>
          <p>&nbsp;|&nbsp;</p>
          <p>2020-09</p>
        </div>
        <p className="flex flex-col gap-1 text-lg font-semibold text-center md:text-2xl lg:text-4xl">
          <p>고창 책마을해리</p>
          <p>부엉이 만들기 프로젝트</p>
        </p>
        {/* <div className="flex gap-3 mt-1 md:mt-3">
          <div className="w-8 p-2 rounded-full md:w-10 bg-boxbg hover:bg-boxbg2">
            <img src={kakao} alt="" className="object-cover" />
          </div>
          <div className="w-8 p-2 rounded-full md:w-10 bg-boxbg hover:bg-boxbg2">
            <img src={link} alt="" className="object-cover" />
          </div>
        </div> */}
      </div>
      {/* 내용 */}
      <div className="flex flex-col items-center gap-1 text-center md:gap-3">
        <img
          src={complete}
          alt="고창 책마을해리 부엉이 만들기 프로젝트 완성 사진"
          className="object-cover"
        />
        <p>
          <br />
          2020년 초겨울 나무위도서관 아래에서 작은 부엉이가 세상을 떠났어요.
          <br />
          이 부엉이를 기리고자 생태환경도서관을 코끼리협동조합에서 만들었습니다.
          <br />
          <br />
        </p>

        <img src={meeting} alt="미팅 사진" className="object-cover" />
        <p>
          <br />
          먼저 책마을해리 촌장님과 만나서 간단하게 회의를 했습니다.
          <br />
          부엉이 모양의 도서관을 만들기 위해서는 위치를 정해야 하고
          <br />
          도서관의 크기, 모양을 먼저 정해야겠죠?
          <br />
          <br />
        </p>

        <img src={location} alt="미팅 사진" className="object-cover" />
        <p>
          <br />
          부엉이도서관이 어디에 들어가면 좋을지
          <br />
          회의 끝에 위치를 정하고 부엉이 도서관을 설계했습니다.
          <br />
          <br />
          <br />
          그럼 이제 부엉이 도서관을 만들어 볼까요?
          <br />
          <br />
          <br />
        </p>

        <img src={make1} alt="제작 사진" className="object-cover" />
        <img src={make2} alt="제작 사진" className="object-cover" />
        <img src={make3} alt="제작 사진" className="object-cover" />
        <img src={make4} alt="제작 사진" className="object-cover" />
        <img src={make5} alt="제작 사진" className="object-cover" />
        <img src={make6} alt="제작 사진" className="object-cover" />
        <img src={make7} alt="제작 사진" className="object-cover" />

        <p>
          <br />
          날이 어두워 질때까지 작업을 계속 한 결과!
          <br />
          <br />
          고창 책마을 해리에는 부엉이 모양 생태환경도서관이 있게 되었답니다.
          <br />
          <br />
          <br />
        </p>

        <img
          src={complete}
          alt="고창 책마을해리 부엉이 만들기 프로젝트 완성 사진"
          className="object-cover"
        />
      </div>
      {/* 하단 */}
      <div className="flex flex-col items-center gap-1 md:gap-3">
        {/* <div className="flex gap-3">
          <div className="w-8 p-2 rounded-full md:w-10 bg-boxbg hover:bg-boxbg2">
            <img src={kakao} alt="" className="object-cover" />
          </div>
          <div className="w-8 p-2 rounded-full md:w-10 bg-boxbg hover:bg-boxbg2">
            <img src={link} alt="" className="object-cover" />
          </div>
        </div> */}
        <Link to="/">
          <button className="h-8 mt-3 text-base rounded-full w-28 md:h-10 bg-boxbg hover:bg-boxbg2">
            이전으로
          </button>
        </Link>
      </div>
    </div>
  );
}

export default BookVillage;
