import React from "react";
import { Link } from "react-router-dom";
import kakao from "../../../assets/img/carousel/story/kakao.png";
import link from "../../../assets/img/carousel/story/link.png";

  // 거리 점등식
  import img1 from "../../../assets/img/story/nightFestival/1.jpg";
  import img2 from "../../../assets/img/story/nightFestival/2.jpg";

  // 구시청 조명
  import img3 from "../../../assets/img/story/nightFestival/3.jpg";
  import img4 from "../../../assets/img/story/nightFestival/4.jpg";
  import img5 from "../../../assets/img/story/nightFestival/5.jpg";

  // 폴리 공연
  import img6 from "../../../assets/img/story/nightFestival/6.jpg";
  import img7 from "../../../assets/img/story/nightFestival/7.jpg";

  // 선물박스
  import img8 from "../../../assets/img/story/nightFestival/8.jpg";
  import img9 from "../../../assets/img/story/nightFestival/9.jpg";

  // 뱅쇼
  import img10 from "../../../assets/img/story/nightFestival/10.jpg";
  import img11 from "../../../assets/img/story/nightFestival/11.jpg";

function NightFestival() {
  return (
    <div className="2xl:w-[60%] w-[90%] mx-auto md:mt-10 mt-5 flex flex-col gap-32">
      {/* <h2>Story {id}</h2> */}
      {/* 상단 */}
      <div className="flex flex-col items-center gap-1 md:gap-3">
        <div className="flex text-sm md:text-base lg:text-lg">
          <p>제작</p>
          <p>&nbsp;|&nbsp;</p>
          <p>2022-09</p>
        </div>
        <p className="flex flex-col gap-1 text-lg font-semibold text-center md:text-2xl lg:text-4xl">
          <p>청년과 음악, 예술 상인이 함께하는</p>
          <p>구시청 나이트 페스티벌</p>
        </p>
        {/* <div className="flex gap-3 mt-1 md:mt-3">
          <div className="w-8 p-2 rounded-full md:w-10 bg-boxbg hover:bg-boxbg2">
            <img src={kakao} alt="" className="object-cover" />
          </div>
          <div className="w-8 p-2 rounded-full md:w-10 bg-boxbg hover:bg-boxbg2">
            <img src={link} alt="" className="object-cover" />
          </div>
        </div> */}
      </div>
      {/* 내용 */}
      <div className="flex flex-col items-center gap-5 text-center md:gap-10">
        <div className="flex flex-col gap-1 md:gap-3">
          <div className="flex w-full">
            <img
              className="object-cover w-full aspect-video"
              src={img1}
              alt=""
            />
          </div>
          <p className="text-sm md:text-lg">구시청 나이트 페스티벌 입구</p>
        </div>
        {/* -- */}
        <div className="flex flex-col gap-1 md:gap-3">
          <div className="flex w-full">
            <img
              className="object-cover w-full aspect-video"
              src={img2}
              alt=""
            />
          </div>
          <div className="flex w-full">
            <img
              className="object-cover w-full aspect-video"
              src={img3}
              alt=""
            />
          </div>
          <p className="text-sm md:text-lg">폴리 디제잉</p>
          <div className="flex w-full">
            <img
              className="object-cover w-full aspect-video"
              src={img4}
              alt=""
            />
          </div>
          <div className="flex w-full">
            <img
              className="object-cover w-full aspect-video"
              src={img5}
              alt=""
            />
          </div>
          <p className="text-sm md:text-lg">펌프와 VR게임 체험장</p>
        </div>
        {/* -- */}
        <div className="flex flex-col gap-1 md:gap-3">
          <div className="flex w-full">
            <img
              className="object-cover w-full aspect-video"
              src={img6}
              alt=""
            />
          </div>
          <div className="flex w-full">
            <img
              className="object-cover w-full aspect-video"
              src={img7}
              alt=""
            />
          </div>
          <p className="text-sm md:text-lg">길거리 노래방</p>
        </div>
        {/* -- */}
        <div className="flex flex-col gap-1 md:gap-3">
          <div className="flex w-full">
            <img
              className="object-cover w-full aspect-video"
              src={img8}
              alt=""
            />
          </div>
          <div className="flex w-full">
            <img
              className="object-cover w-full aspect-video"
              src={img9}
              alt=""
            />
          </div>
          <div className="flex w-full">
            <img
              className="object-cover w-full aspect-video"
              src={img10}
              alt=""
            />
          </div>
          <div className="flex w-full">
            <img
              className="object-cover w-full aspect-video"
              src={img11}
              alt=""
            />
          </div>
          <p className="text-sm md:text-lg">축제 부스</p>
        </div>
      </div>

      {/* 하단 */}
      <div className="flex flex-col items-center gap-1 md:gap-3">
        {/* <div className="flex gap-3">
          <div className="w-8 p-2 rounded-full md:w-10 bg-boxbg hover:bg-boxbg2">
            <img src={kakao} alt="" className="object-cover" />
          </div>
          <div className="w-8 p-2 rounded-full md:w-10 bg-boxbg hover:bg-boxbg2">
            <img src={link} alt="" className="object-cover" />
          </div>
        </div> */}
        <Link to="/">
          <button className="h-8 mt-3 text-base rounded-full w-28 md:h-10 bg-boxbg hover:bg-boxbg2">
            이전으로
          </button>
        </Link>
      </div>
    </div>
  );
}

export default NightFestival;
