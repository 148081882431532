import React from "react";
import { useNavigate, Link } from "react-router-dom";
import ms from "../../assets/img/company/ms.png";
import MakerCard from "../../components/makerspace/MakerCard";
import MakerCard2 from "../../components/makerspace/MakerCard2";

// 이미지 파일 경로 배열
const cardImages = [
  require("../../assets/img/makerspace/make.jpg"),
  require("../../assets/img/makerspace/teach.jpg"),
  require("../../assets/img/makerspace/consulting.jpg"),
];

const cardImages2 = [
  require("../../assets/img/makerspace/1.jpg"),
  require("../../assets/img/makerspace/2.jpg"),
  require("../../assets/img/makerspace/3.jpg"),
  require("../../assets/img/makerspace/4.png"),
  require("../../assets/img/makerspace/5.jpg"),
  require("../../assets/img/makerspace/6.jpg"),
];

function Makerspace1() {
  const cardTexts = ["제작 대행", "출장 교육", "컨설팅"];
  const cardTexts2 = ["디지털제작", "목공", "후가공", "철공", "패브릭", "기타"];

  const navigate = useNavigate();

  function handleCardClick(category) {
    // 카테고리 선택 상태를 `Makerspace2`로 전달
    navigate("/makerspace2", { state: { selectedCategory: category } });
    // <Link to={{
    //   pathname: "/makerspace2",
    //   state: { selectedCategory: category }
    // }}>
    //   // ... 해당 카드 렌더링 ...
    // </Link>
  }

  return (
    <div className="2xl:w-[60%] w-[90%] mx-auto md:mt-10 mt-5 flex flex-col md:gap-14 gap-8 leading-7">
      {/* 1 */}
      <div className="flex flex-col items-center w-full p-3 rounded-lg md:p-5 md:flex-row bg-boxbg">
        <div className="flex items-center justify-center w-full md:w-1/6">
          <div className="flex items-center justify-center bg-white rounded-full md:w-20 w-14 aspect-square">
            <img src={ms} alt="" className="w-12 h-1w-12" />
          </div>
        </div>
        <div className="flex flex-col w-full gap-1 pr-0 md:w-5/6 md:gap-2 md:pr-6">
          <p className="mt-2 text-base font-semibold text-center md:text-left md:text-xl md:mt-0">
            코끼리메이커스페이스
          </p>
          <p className="text-sm md:text-base">
            코끼리 협동조합에서 운영하고 있는 메이커스페이스입니다.
            <br />
            지역 주민, 메이커들을 대상으로 매번 새로운 주제의 워크숍과
            메이커톤을 개최하여 메이커 문화 확산에 기여하고 있습니다.
          </p>
        </div>
      </div>
      {/* 2 */}
      <div className="flex flex-col gap-2 md:grid-cols-2 md:grid-rows-1 md:gap-4 md:grid">
        <div className="flex items-center h-full col-span-1 gap-4 p-3 rounded-lg md:p-5 bg-boxbg ">
          <div className="flex items-center justify-center w-16 h-16 text-xl bg-white rounded-full aspect-square">
            ⏰
          </div>
          <div className="flex flex-col w-full gap-1">
            <p className="text-sm font-semibold md:text-lg">운영 시간</p>
            <p className="text-xs md:text-sm">
              평일 10:00 ~ 19:00
              <br />
              휴관일: 주말 및 법정 공휴일
            </p>
          </div>
        </div>
        <Link to="https://map.naver.com/p/entry/place/1115379007?lng=126.9043032&lat=35.1652632&placePath=%2Fbooking%3FbookingRedirectUrl%3Dhttps%3A%2F%2Fm.booking.naver.com%2Fbooking%2F10%2Fbizes%2F370690%3Ftheme%3Dplace%26entry%3Dpll%26entry%3Dpll&area=pll&c=15.00,0,0,0,dh">
          <div className="flex items-center h-full col-span-1 gap-4 p-3 rounded-lg md:p-5 bg-boxbg hover:bg-boxbg2">
            <div className="flex items-center justify-center w-16 h-16 text-xl bg-white rounded-full aspect-square">
              📆
            </div>
            <div className="flex flex-col w-full gap-1">
              <p className="text-sm font-semibold text-blue-600 underline md:text-lg">
                장비 예약하기
              </p>
              <p className="text-xs md:text-sm">네이버예약 페이지로 이동</p>
            </div>
          </div>
        </Link>
      </div>
      {/* 3 */}
      <div>
        <p className="mb-2 text-lg font-semibold md:mb-4 md:text-2xl">
          서비스 안내
        </p>
        <div className="flex flex-col gap-1 md:gap-2">
          {/* 장비 이용 */}
          <p className="text-sm font-semibold md:text-lg">▶ 장비 이용</p>
          <div className="flex flex-wrap gap-2 text-sm md:gap-4 lg:text-base">
            <Link to="https://blog.naver.com/co77iri/221897816753">
              <div className="flex items-center justify-center w-20 text-blue-600 underline rounded-full md:w-24 lg:w-32 bg-boxbg hover:bg-boxbg2 aspect-square">
                멤버십 가입
              </div>
            </Link>
            <p className="flex items-center text-base md:text-lg lg:text-2xl text-boxbg2 ">
              ▶
            </p>
            <div className="flex items-center justify-center w-20 text-center rounded-full md:w-24 lg:w-32 bg-boxbg aspect-square">
              장비 교육
              <br />
              이수
            </div>
            <p className="flex items-center text-base md:text-lg lg:text-2x text-boxbg2 ">
              ▶
            </p>
            <div className="flex items-center justify-center w-20 rounded-full md:w-24 lg:w-32 bg-boxbg aspect-square">
              장비 예약
            </div>
            <p className="flex items-center text-base md:text-lg lg:text-2x text-boxbg2 ">
              ▶
            </p>
            <div className="flex items-center justify-center w-20 rounded-full md:w-24 lg:w-32 bg-boxbg aspect-square">
              장비 사용
            </div>
            <p className="flex items-center text-base md:text-lg lg:text-2x text-boxbg2 ">
              ▶
            </p>
            <div className="flex items-center justify-center w-20 rounded-full md:w-24 lg:w-32 bg-boxbg aspect-square">
              정리 정돈
            </div>
          </div>

          {/* 제작/교육/컨설팅 */}
          <p className="mt-4 text-sm font-semibold md:text-lg mb:mt-8">
            ▶ 제작/교육/컨설팅
          </p>
          <p className="text-xs text-orange-500 md:text-base">
            ※ 제작 대행, 출장 교육, 메이커스페이스 구축, 창업 지원, 지역 역
            활성화 등 다양한 분야의 서비스를 제공해 드립니다.
          </p>
          <div className="flex flex-col grid-cols-3 grid-rows-1 gap-2 mb-2 md:grid md:gap-4 md:mb-4">
            {cardTexts.map((text, index) => (
              <MakerCard key={index} text={text} image={cardImages[index]} />
            ))}
          </div>

          {/* 보유 장비 */}
          <p className="mt-4 text-sm font-semibold md:text-lg mb:mt-8">
            ▶ 보유 장비
          </p>
          <p className="text-xs text-orange-500 md:text-base">
            ※ 각 카테코리를 클릭하면 상세 장비 목록을 확인하실 수 있습니다.
          </p>
          <div className="flex flex-col grid-cols-3 grid-rows-2 gap-2 mb-2 md:grid md:gap-4 md:mb-4">
            {cardTexts2.map((text, index) => (
              <div onClick={() => handleCardClick(text)}>
                <MakerCard2
                  key={index}
                  text={text}
                  image={cardImages2[index]}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Makerspace1;
