import React, { useEffect, useState } from "react";
import axios from "axios";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/autoplay";
import { Navigation, Pagination, Mousewheel, Keyboard, Autoplay } from "swiper/modules";

import Posts from "../pagination/Posts";
import PaginationComponent from "../pagination/PaginationComponent";

function Work1() {
  const currentCategory = "기획";

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPosts, setTotalPosts] = useState(0); // 전체 포스트 개수 상태
  const postsPerPage = 12; // 한 페이지에 표시할 포스트 수

  // 포스트 데이터 가져오기
  useEffect(() => {
    setLoading(true);
    axios
      .get(
        `https://co77iri.com/fetchArticles/category/${currentCategory}/${currentPage}`
      )
      .then((res) => {
        setData(res.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setLoading(false);
      });
  }, [currentPage, currentCategory]);

  // 전체 포스트 개수 가져오기
  useEffect(() => {
    axios
      .get(`https://co77iri.com/getTotalArticleNum/${currentCategory}`)
      .then((res) => {
        setTotalPosts(res.data.total);
      })
      .catch((error) =>
        console.error("Error fetching total article number:", error)
      );
  }, [currentCategory]); // currentCategory가 변경될 때만 실행

  // 전체 페이지 수 계산
  const totalPages = Math.ceil(totalPosts / postsPerPage);

  return (
    <div className="2xl:w-[60%] w-[90%] mx-auto md:mt-10 mt-5 flex flex-col md:gap-24 gap-12 leading-7">
      <Swiper
        // navigation={true} 화살표x
        pagination={{
          clickable: true,
        }}
        mousewheel={true}
        keyboard={true}
        autoplay={{
          delay: 5000,
          disableOnInteraction: false
        }}
        modules={[Navigation, Pagination, Mousewheel, Keyboard, Autoplay]}
        className="w-full h-full text-xl text-center rounded-lg cursor-pointer bg-boxbg"
      >
        <SwiperSlide className="px-10 pt-3 md:pt-4 pb-9 md:px-5">
          <p className="mt-2 text-base font-semibold text-center md:text-xl md:mt-0">
            💡 기술을 활용한 일상 문제 해결
          </p>
          <p className="text-sm text-center md:text-base">
            우리 주변에 산재해 있는 다양한 문제를 인식하고 어떤 방식으로 해결할
            수 있을지 고민합니다.
            <br />
            함께 고민하고 만들면서 문제를 해결하기 위해 노력합니다.
          </p>
        </SwiperSlide>
        <SwiperSlide className="px-10 pt-3 md:pt-4 pb-9 md:px-5">
          <p className="mt-2 text-base font-semibold text-center md:text-xl md:mt-0">
            🎈 행사 기획 및 운영
          </p>
          <p className="text-sm text-center md:text-base">
            자체 네트워크를 통해 메이커, 기업, 지역 주민 등 다양한 사람들과 함께
            행사를 기획하고 운영합니다.
            <br />
            메이커 행사, 지역 행사 등 참신하고 새로운 기획을 통해 행사를 더욱
            빛나게 합니다.
          </p>
        </SwiperSlide>
      </Swiper>

      <div className="grid grid-cols-1 gap-10 md:grid-cols-2 lg:grid-cols-3 md:gap-14">
        <Posts data={data} loading={loading} />
      </div>

      <PaginationComponent
        currentPage={currentPage}
        totalPages={totalPages}
        paginate={setCurrentPage}
      />
    </div>
  );
}

export default Work1;
