import React from "react";
import CompanyGrid from "../grid/CompanyGrid";
import companyData from "../../data/companyData";
import { Link } from "react-router-dom";
import {
  Container as MapDiv,
  NaverMap,
  Marker,
  useNavermaps,
} from "react-naver-maps";

function Company() {
  const navermaps = useNavermaps();
  return (
    <div className="2xl:w-[60%] w-[90%] mx-auto md:mt-10 mt-5 flex flex-col md:gap-14 gap-8 leading-7">
      {/* 1 */}
      <div className="flex flex-col items-center w-full p-3 rounded-lg md:p-5 md:flex-row bg-boxbg">
        <div className="w-1/6 text-2xl text-center md:text-4xl">🐘</div>
        <div className="flex flex-col w-full gap-1 pr-0 md:w-5/6 md:gap-2 md:pr-6">
          <p className="mt-2 text-base font-semibold text-center md:text-left md:text-xl md:mt-0">
            든든한 사회도전망 코끼리협동조합
          </p>
          <p className="text-sm md:text-base">
            코끼리협동조합은 지역, 혁신, 공유의 핵심가치를 지향하는
            사회적기업입니다.
            <br />
            일상의 실험을 통해 스스로 삶을 설계하고 만들어가는 메이커그룹으로
            디지털 기술 연대를 통해 사회문제를 해결하기 위해 도전합니다.
          </p>
        </div>
      </div>
      {/* 2 */}
      <div>
        <p className="mb-2 text-lg font-semibold md:mb-4 md:text-2xl">
          코끼리가 하는 일
        </p>
        <div className="flex flex-col gap-2 md:grid-cols-2 md:grid-rows-3 lg:grid-cols-3 lg:grid-rows-2 md:gap-4 md:grid">
          {companyData.map((data, index) => (
            <Link to={data.path}>
              <CompanyGrid key={index} data={data} />
            </Link>
          ))}
        </div>
      </div>
      {/* 3 */}
      <div>
        <p className="mb-2 text-lg font-semibold md:mb-4 md:text-2xl">
          문의 & 정보
        </p>
        <MapDiv
          style={{
            height: 400,
            marginBottom: "8px",
          }}
        >
          <NaverMap
            defaultCenter={new navermaps.LatLng(35.1652386, 126.9043325)}
            defaultZoom={17}
          >
            <Marker position={new navermaps.LatLng(35.1652386, 126.9043325)} />
          </NaverMap>
        </MapDiv>

        <div>
          <p className="mb-2 text-xs text-orange-500 md:text-base md:mb-4">
            ※ 문의, 업무 협력, 제안 등의 문의는 아래 기재된 전화번호 또는
            이메일로 연락 부탁드립니다.
          </p>
          <div className="grid grid-cols-4 grid-rows-3 text-xs md:grid-cols-6 md:text-base">
            <p className="col-span-1">ADDRESS</p>
            <p className="col-span-3 md:col-span-5">
              광주 광역시 북구 무등로 190
            </p>
            <p className="col-span-1">PHONE</p>
            <p className="col-span-3 md:col-span-5">062-513-2014</p>
            <p className="col-span-1">EMAIL</p>
            <p className="col-span-3 md:col-span-5">co77iri@gmail.com</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Company;
