function MakerCard3({ title, src, link }) {
  const handleClick = () => {
    window.location.href = link;
  };

  return (
    <div className="relative w-full col-span-1">
      <div
        className="w-full bg-gray-200 rounded-lg cursor-pointer aspect-square group"
        onClick={handleClick}
      >
        <img
          src={src}
          alt={title}
          className="object-cover w-full border rounded-lg aspect-square border-boxstroke"
        />
      </div>
      <p className="flex justify-center w-full text-sm md:text-base">{title}</p>
    </div>
  );
}

export default MakerCard3;


// function MakerCard3({ title, src }) {
//   return (
//     <div className="relative w-full col-span-1">
//       <div className="w-full bg-gray-200 rounded-lg cursor-pointer aspect-square group">
//         <img
//           src={src}
//           alt=""
//           className="object-cover w-full border rounded-lg aspect-square border-boxstroke"
//         />
//       </div>
//       <p className="flex justify-center w-full text-sm md:text-base">{title}</p>
//     </div>
//   );
// }

// export default MakerCard3;
