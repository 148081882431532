import React from "react";
import "../../assets/styles/PaginationComponent.css";

const PaginationComponent = ({ currentPage, totalPages, paginate }) => {
  const pageNumberLimit = 5; // 한 페이지 세트에 표시할 페이지 수
  const lastPageSetNum = Math.ceil(totalPages / pageNumberLimit); // 마지막 페이지 세트 번호 계산
  const currentSet = Math.ceil(currentPage / pageNumberLimit); // 현재 페이지 세트 번호 계산

  // 현재 페이지 세트의 시작 페이지와 마지막 페이지 계산
  const startPage = (currentSet - 1) * pageNumberLimit + 1;
  const endPage = Math.min(startPage + pageNumberLimit - 1, totalPages);

  // '이전'과 '다음' 버튼의 표시 여부 결정
  const showPrevButton = currentSet > 1;
  const showNextButton = currentSet < lastPageSetNum;

  // 페이지 번호 목록 생성
  const pageNumbers = [];
  for (let i = startPage; i <= endPage; i++) {
    pageNumbers.push(i);
  }

  const handleClick = (number, event) => {
    event.preventDefault();
    paginate(number);
  };

  return (
    <nav>
      <ul className="gap-3 mb-12 pagination">
        {showPrevButton && (
          <li>
            <a onClick={(e) => handleClick(startPage - 1, e)} href="#!">
              &laquo; 이전
            </a>
          </li>
        )}
        {pageNumbers.map((number) => (
          <li
            key={number}
            className={`page-item ${currentPage === number ? "active" : ""}`}
          >
            <a onClick={(e) => handleClick(number, e)} href="#!">
              {number}
            </a>
          </li>
        ))}
        {showNextButton && (
          <li>
            <a onClick={(e) => handleClick(endPage + 1, e)} href="#!">
              다음 &raquo;
            </a>
          </li>
        )}
      </ul>
    </nav>
  );
};

export default PaginationComponent;
