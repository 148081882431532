const MachineData = [
  {
    link: "https://booking.naver.com/booking/10/bizes/370690/items/3481247",
    imgSrc:
      "https://naverbooking-phinf.pstatic.net/20210106_83/16099093962880SOUc_JPEG/%C5%A5%BA%F1%C4%DC_3DP-210F.jpg?type=w1500",
    title: "3D프린터(CUBICON)",

    tag: "디지털제작",
  },
  {
    link: "https://booking.naver.com/booking/10/bizes/370690/items/3643028",
    imgSrc:
      "https://naverbooking-phinf.pstatic.net/20201027_134/1603786396554nCjWE_JPEG/IMG_0885.JPG?type=w1500",
    title: "UV프린터",

    tag: "디지털제작",
  },
  {
    link: "https://booking.naver.com/booking/10/bizes/370690/items/3750620",
    imgSrc:
      "https://naverbooking-phinf.pstatic.net/20210106_69/16099097364525xw4n_JPEG/HP_Latex_365.jpg?type=w1500",
    title: "HP Latex 365 프린터",

    tag: "디지털제작",
  },
  {
    link: "https://booking.naver.com/booking/10/bizes/370690/items/3750697",
    imgSrc:
      "https://naverbooking-phinf.pstatic.net/20210106_31/1609911715565YxziH_JPEG/silhouette_CAMEO3.jpg?type=w1500",
    title: "실루엣카메오3(비닐커터)",

    tag: "디지털제작",
  },
  {
    link: "https://booking.naver.com/booking/10/bizes/370690/items/3481260",
    imgSrc:
      "https://naverbooking-phinf.pstatic.net/20200616_292/1592290132447cRtC0_JPEG/IMG_7816.JPG?type=w1500",
    title: "CNC",

    tag: "목공",
  },
  {
    link: "https://booking.naver.com/booking/10/bizes/370690/items/3750715",
    imgSrc:
      "https://naverbooking-phinf.pstatic.net/20220803_71/1659513749471nxhSI_PNG/%B8%F1%B0%F8%BD%C7.png?type=w1500",
    title: "목공 장비",
    tag: "목공",
  },
  {
    link: "https://booking.naver.com/booking/10/bizes/370690/items/3751308",
    imgSrc:
      "https://naverbooking-phinf.pstatic.net/20220803_149/1659514395209V14DQ_PNG/%BB%F7%B5%F9%BD%C72.png?type=w1500",
    title: "샌딩 장비",
    tag: "후가공",
  },
  {
    link: "https://booking.naver.com/booking/10/bizes/370690/items/3751313",
    imgSrc:
      "https://naverbooking-phinf.pstatic.net/20210106_118/1609923974184HMHpk_JPEG/KakaoTalk_20210106_180549097.jpg?type=w1500",
    title: "후가공 장비",
    tag: "목공",
  },
  {
    link: "https://booking.naver.com/booking/10/bizes/370690/items/3481255",
    imgSrc:
      "https://naverbooking-phinf.pstatic.net/20220803_224/1659513414020YF74f_PNG/%B7%B9%C0%CC%C0%FA%C4%BF%C5%CD2.png?type=w1500",
    title: "레이저커터(비금속용)",
    tag: "디지털제작",
  },
  {
    link: "https://booking.naver.com/booking/10/bizes/370690/items/3750882",
    imgSrc:
      "https://naverbooking-phinf.pstatic.net/20220803_255/1659514106968hGtA6_PNG/%BF%EB%C1%A2%C5%D7%C0%CC%BA%ED.png?type=w1500",
    title: "철공 장비",
    tag: "철공",
  },
  {
    link: "https://booking.naver.com/booking/10/bizes/370690/items/3643298",
    imgSrc:
      "https://naverbooking-phinf.pstatic.net/20220803_151/1659513146391akv1O_PNG/%B7%B9%C0%CC%C0%FA%B0%A2%C0%CE%B1%E22.png?type=w1500",
    title: "레이저각인기(비금속용)",
    tag: "디지털제작",
  },
  {
    link: "https://booking.naver.com/booking/10/bizes/370690/items/3750574",
    imgSrc:
      "https://naverbooking-phinf.pstatic.net/20220803_151/1659513146391akv1O_PNG/%B7%B9%C0%CC%C0%FA%B0%A2%C0%CE%B1%E22.png?type=w1500",
    title: "레이저각인기(금속용)",
    tag: "디지털제작",
  },
  {
    link: "https://booking.naver.com/booking/10/bizes/370690/items/3751328",
    imgSrc:
      "https://naverbooking-phinf.pstatic.net/20210106_281/16099243121062kr7p_JPEG/%C1%F8%B0%F8%C5%BB%C6%F7%B1%E2.jpg?type=w1500",
    title: "진공 탈포기",
    tag: "후가공",
  },
  {
    link: "https://booking.naver.com/booking/10/bizes/370690/items/3751325",
    imgSrc:
      "https://naverbooking-phinf.pstatic.net/20210106_52/1609924166718ajJJc_JPEG/KakaoTalk_20210106_153931541_04.jpg?type=w1500",
    title: "도색 장비",
    tag: "후가공",
  },
  {
    link: "https://booking.naver.com/booking/10/bizes/370690/items/3481282",
    imgSrc:
      "https://naverbooking-phinf.pstatic.net/20200616_84/1592290634300UWgIM_JPEG/IMG_7847.JPG?type=w1500",
    title: "재봉틀(오버록)",
    tag: "패브릭",
  },
  {
    link: "https://booking.naver.com/booking/10/bizes/370690/items/3481268",
    imgSrc:
      "https://naverbooking-phinf.pstatic.net/20200616_52/1592290454291gUh5W_JPEG/pr-600%A4%C1%A4%C1.jpg?type=w1500",
    title: "디지털자수기(PR-600II)",
    tag: "패브릭",
  },
  {
    link: "https://booking.naver.com/booking/10/bizes/370690/items/3721575",
    imgSrc:
      "https://naverbooking-phinf.pstatic.net/20201214_24/1607915202508h2wLs_JPEG/sunstar_380.jpg?type=w1500",
    title: "가죽 재봉틀(타프미싱)",
    tag: "패브릭",
  },
  {
    link: "https://booking.naver.com/booking/10/bizes/370690/items/3721576",
    imgSrc:
      "https://naverbooking-phinf.pstatic.net/20201214_44/1607915356125XjhDH_JPEG/%BA%EA%B6%F3%B4%F57300.jpg?type=w1500",
    title: "공업용 재봉틀(본봉 미싱)1",
    tag: "패브릭",
  },
  {
    link: "https://booking.naver.com/booking/10/bizes/370690/items/3721582",
    imgSrc:
      "https://naverbooking-phinf.pstatic.net/20201214_87/1607915818684ITmbu_JPEG/0010040002012.jpg?type=w1500",
    title: "공업용 재봉틀(본봉 미싱)2",
    tag: "패브릭",
  },
  {
    link: "https://booking.naver.com/booking/10/bizes/370690/items/3643063",
    imgSrc:
      "https://naverbooking-phinf.pstatic.net/20201109_227/1604915151103kvmhA_JPEG/IMG_0972.JPG?type=w1500",
    title: "코끼리 스튜디오(사진/영상)",
    tag: "기타",
  },
];

export default MachineData;
