import React, { useState } from "react";
import axios from "axios";

function AdminAdd() {
  // 각 입력 필드의 값을 상태로 관리
  const [formData, setFormData] = useState({
    date: "",
    category: "",
    title: "",
    content: "",
    link: "",
    thumbnail: null, // 썸네일 이미지는 파일이므로 초기값을 null로 설정
  });

  // 입력 필드 값이 변경될 때마다 상태 업데이트
  const handleChange = (e) => {
    const { name, value, files } = e.target;

    // 파일 입력인 경우 썸네일 이미지 업데이트
    if (name === "thumbnail") {
      setFormData((prevData) => ({
        ...prevData,
        thumbnail: files[0], // 파일은 배열로 전달되므로 첫 번째 파일 사용
      }));
    } else {
      // 일반 입력 필드의 경우 해당 필드 업데이트
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const handleTextSubmit = async (textData) => {
    try {
      const response = await axios.post(
        "http://localhost:8800/adminAdd",
        textData
      );
      console.log(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const handleImageSubmit = async (imageData) => {
    try {
      const response = await axios.post(
        "http://localhost:8800/adminAddImage",
        imageData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      console.log("Image upload successful:", response.data);
    } catch (error) {
      if (error.response) {
        // 서버가 응답하지만 상태 코드가 2xx가 아닌 경우
        console.error("Server responded with an error:", error.response.data);
      } else if (error.request) {
        // 서버에 요청을 보냈지만 응답을 받지 못한 경우
        console.error("No response received from the server:", error.request);
      } else {
        // 요청을 보내기 전에 발생한 오류
        console.error("Error before sending the request:", error.message);
      }
    }
  };

  const date2 = (date) => {
    return date.replace(/-/g, "").slice(2);
  };
  const thumbnail2 = (thumbnail) => {
    return thumbnail.type.split("/")[1];
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const { date, category, title, content, link, thumbnail } = formData;

      // 텍스트 데이터 전송
      const textData = {
        date,
        category,
        title,
        content,
        link,
        img: `${date2(date)}.${thumbnail2(thumbnail)}`,
      };
      await handleTextSubmit(textData);

      // 이미지 데이터 전송
      const dateRegex = /\d{4}-\d{2}-\d{2}/; // 정규식을 사용하여 날짜 형식 확인
      const isDateValid = dateRegex.test(date);

      if (isDateValid && thumbnail && thumbnail.type.startsWith("image/")) {
        const datePart = date2(date); // 정규식을 사용하여 유효한 날짜 부분 추출
        const imageExtension = thumbnail2(thumbnail); // 이미지 확장자 추출

        const imageName = `${datePart}.${imageExtension}`;
        const imageData = new FormData();

        // 키(필드명), 값(파일), 파일명 - 서버에서 필드명으로 파일을 받아 처리함
        imageData.append("thumbnail", thumbnail, imageName);
        // console.log("서버로 전송할 imgData", [...imageData.entries()]);
        await handleImageSubmit(imageData);
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="2xl:w-[60%] w-[90%] mx-auto">
      <div className="flex flex-col items-center justify-center h-screen">
        <p className="mb-10 text-4xl font-semibold">관리자 페이지</p>
        <form
          className="flex flex-col w-full gap-5 text-base sm:w-80"
          onSubmit={handleSubmit}
        >
          <input
            type="text"
            placeholder="날짜 (2024-01-17)"
            className="p-2 border"
            name="date"
            required
            onChange={handleChange}
          />
          <select
            className="p-2 border"
            name="category"
            required
            onChange={handleChange}
            value={formData.category} // 선택된 값 설정
          >
            <option value="">분류를 선택해주세요</option>
            <option value="교육">교육</option>
            <option value="기획">기획</option>
            <option value="제작">제작</option>
          </select>
          <input
            type="text"
            placeholder="제목"
            className="p-2 border"
            name="title"
            required
            onChange={handleChange}
          />
          <textarea
            type="text"
            placeholder="내용"
            className="p-2 border h-36"
            name="content"
            required
            onChange={handleChange}
          />
          <input
            type="text"
            placeholder="블로그 링크"
            className="p-2 border"
            name="link"
            required
            onChange={handleChange}
          />
          <input
            type="file"
            placeholder="썸네일 이미지"
            className="p-2 border"
            accept="image/jpeg, image/jpg, image/png"
            name="thumbnail"
            required
            onChange={handleChange}
          />

          <button
            type="submit"
            className="p-2 font-semibold bg-orange-400 hover:bg-orange-200"
          >
            저장
          </button>
        </form>
      </div>
    </div>
  );
}

export default AdminAdd;
