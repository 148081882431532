import React from "react";
import "../../assets/styles/Posts.css";

const Posts = ({ data, loading }) => {
  const extractImageNameFromLink = (link) => {
    const match = link.match(/\/(\d+)$/);
    return match ? match[1] : null;
  };

  const handlePostClick = (link) => {
    window.location.href = link;
  };

  return (
    <>
      {loading && <div>Loading...</div>}
      {data.map((p) => (
        <div  key={p.id}
              className="post-item flex flex-col gap-2 md:gap-5 p-3"
              style={{ cursor: 'pointer' }}
              onClick={() => handlePostClick(p.link)}
        >
          <div className="relative w-full pb-[100%] overflow-hidden">
            <img
              src={`https://co77iri.com/img/${extractImageNameFromLink(
                p.link
              )}.png`}
              alt={p.title}
              className="absolute top-0 w-full"
            />
          </div>
          <p className="overflow-hidden text-base font-semibold whitespace-normal md:text-lg text-ellipsis line-clamp-1">
            {p.title}
          </p>
          <p className="mb-10 overflow-hidden text-sm whitespace-normal md:text-base text-ellipsis line-clamp-3">
            {p.content}
          </p>
        </div>
      ))}
    </>
  );
};

export default Posts;
