import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import MachineData from "../../data/MachineData";
import MakerCard3 from "../makerspace/MakerCard3";

function Makerspace2() {
  const location = useLocation();
  const categories = [
    "전체",
    "디지털제작",
    "목공",
    "후가공",
    "철공",
    "패브릭",
    "기타",
  ];

  // const [selectedCategory, setSelectedCategory] = useState("전체");
  // `useLocation`을 사용하여 상태 정보 가져오기
  const initialState = location.state?.selectedCategory || "전체";
  const [selectedCategory, setSelectedCategory] = useState(initialState);

  // 카테고리 클릭시 이벤트
  const handleCategoryClick = (category) => {
    setSelectedCategory(category);
  };

  const filteredMachines =
    selectedCategory === "전체"
      ? MachineData
      : MachineData.filter((machine) => machine.tag === selectedCategory);

  return (
    <div className="2xl:w-[60%] w-[90%] mx-auto md:mt-10 mt-5 flex flex-col gap-2 md:gap-4 leading-7">
      <p className="text-xs text-orange-500 md:text-base">
        ※ 각 장비를 클릭하면 장비 예약 페이지로 이동합니다.
      </p>

      {/* 추가 */}
      <div className="flex items-center justify-center gap-4 py-3 text-xs rounded-lg md:py-4 lg:gap-10 md:gap-7 lg:text-base md:text-sm bg-boxbg">
        {categories.map((category) => (
          <p
            key={category}
            onClick={() => handleCategoryClick(category)}
            className={`cursor-pointer ${
              selectedCategory === category ? "font-semibold" : "font-medium"
            }`}
          >
            {category}
          </p>
        ))}
      </div>
      <div className="flex flex-col gap-2 lg:grid-cols-4 md:grid-cols-2 md:grid md:gap-4">
        {filteredMachines.map((machine, index) => (
          <MakerCard3
            key={index}
            title={machine.title}
            src={machine.imgSrc}
            link={machine.link}
          />
        ))}
      </div>
    </div>
  );
}

export default Makerspace2;
