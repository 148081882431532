import React, { useEffect, useState } from "react";
import axios from "axios";
import Posts from "./Posts";
import PaginationComponent from "./PaginationComponent";

function Project() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPosts, setTotalPosts] = useState(0); // 전체 포스트 개수 상태
  const postsPerPage = 12; // 한 페이지에 표시할 포스트 수

  // 전체 페이지 수 계산
  const totalPages = Math.ceil(totalPosts / postsPerPage);

  useEffect(() => {
    setLoading(true);
    // 현재 페이지에 해당하는 포스트 가져오기
    axios
      .get(`https://co77iri.com/fetchArticles/${currentPage}`)
      .then((res) => {
        setData(res.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setLoading(false);
      });

    // 전체 포스트 개수 가져오기
    axios
      .get("https://co77iri.com/getTotalArticleNum")
      .then((res) => {
        setTotalPosts(res.data.total);
      })
      .catch((error) =>
        console.error("Error fetching total article number:", error)
      );
  }, [currentPage]);

  return (
    <div>
      <div className="grid grid-cols-1 gap-10 pb-24 md:grid-cols-2 lg:grid-cols-3 md:gap-14">
        <Posts data={data} loading={loading} className="cursor-pointer" />
      </div>
      <PaginationComponent
        currentPage={currentPage}
        totalPages={totalPages}
        paginate={setCurrentPage}
      />
    </div>
  );
}

export default Project;
