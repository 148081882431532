import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import "./App.css";
import Cookies from "js-cookie";

import Layout from "./components/layouts/Layout";
import Home from "./components/views/Home";
import Company from "./components/views/Company";
import Admin from "./components/views/Admin";
import AdminAdd from "./components/views/AdminAdd";
import Work1 from "./components/views/Work1";
import Work2 from "./components/views/Work2";
import Work3 from "./components/views/Work3";
import Work4 from "./components/views/Work4";
import Makerspace1 from "./components/views/Makerspace1";
import Makerspace2 from "./components/views/Makerspace2";
import Yeogeu from "./components/views/Yeogeu";

import { NavermapsProvider } from "react-naver-maps";

import BookVillage from "./components/views/story/bookVillage";
import NightFestival from "./components/views/story/nightFestival";
import FleaMarket from "./components/views/story/FleaMarket";
import SinanMakerEdu from "./components/views/story/SinanMakerEdu";
import BeGlocalHero from "./components/views/story/BeGlocalHero";
import LightFestival from "./components/views/story/LightFestival";

function App() {
  // 토큰이 있는지 확인
  const token = Cookies.get("token");

  return (
    <NavermapsProvider ncpClientId="xwoh85ifbm">
      <Router>
        <Routes>
          {/* 홈 */}
          <Route path="/" element={<Layout />}>
            <Route index element={<Home />} />
          </Route>

          {/* 회사 소개 */}
          <Route path="/company" element={<Layout />}>
            <Route index element={<Company />} />
          </Route>

          {/* 여그 공간 소개 */}
          <Route path="/yeogeu" element={<Layout />}>
            <Route index element={<Yeogeu />} />
          </Route>
          {/* 메이커스페이스 소개 */}
          <Route path="/makerspace1" element={<Layout />}>
            <Route index element={<Makerspace1 />} />
          </Route>
          {/* 장비 소개 */}
          <Route path="/makerspace2" element={<Layout />}>
            <Route index element={<Makerspace2 />} />
          </Route>
          {/* 기획 */}
          <Route path="/work1" element={<Layout />}>
            <Route index element={<Work1 />} />
          </Route>
          {/* 제작 */}
          <Route path="/work2" element={<Layout />}>
            <Route index element={<Work2 />} />
          </Route>
          {/* 교육 */}
          <Route path="/work3" element={<Layout />}>
            <Route index element={<Work3 />} />
          </Route>
          {/* 로컬 */}
          <Route path="/work4" element={<Layout />}>
            <Route index element={<Work4 />} />
          </Route>

          {/* 스토리 페이지들*/}
          {/* 2020-09 고창 책마을해리 부엉이 프로젝트  */}
          <Route path="/story/bookVillage" element={<Layout />}>
            <Route index element={<BookVillage />} />
          </Route>
          {/* 2022-07 신안군 찾아가는 메이커 교육 */}
          <Route path="/story/sinanMakerEdu" element={<Layout />}>
            <Route index element={<SinanMakerEdu />} />
          </Route>
          {/* 2022-09 구시청 나이트 페스티벌 */}
          <Route path="/story/nightFestival" element={<Layout />}>
            <Route index element={<NightFestival />} />
          </Route>
          {/* 2022-09 오월첫동네 플리마켓 */}
          <Route path="/story/fleaMarket" element={<Layout />}>
            <Route index element={<FleaMarket />} />
          </Route>

          {/* 2023-05 Be 글로컬 히어로 */}
          <Route path="/story/beGlocalHero" element={<Layout />}>
            <Route index element={<BeGlocalHero />} />
          </Route>
          {/* 2023-12 광주빛축제 */}
          <Route path="/story/lightFestival" element={<Layout />}>
            <Route index element={<LightFestival />} />
          </Route>

          {/* 관리자페이지 */}
          <Route path="/admin" element={<Admin />} />
          <Route
            path="/adminadd"
            element={token ? <AdminAdd /> : <Navigate to="/admin" />}
          />
        </Routes>
      </Router>
    </NavermapsProvider>
  );
}

export default App;
