import React from "react";
import Carousel from "../carousel/Carousel";
import Swiper from "../swiper/Swiper";
import Project from "../pagination/Project";

function Home() {
  return (
    <div>
      {/* <Carousel /> */}
      <Swiper />
      <div className="2xl:w-[60%] w-[90%] mx-auto md:mt-10 mt-5">
        <Project />
      </div>
    </div>
  );
}

export default Home;
