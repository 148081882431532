import React from "react";

function CompanyGrid({ data }) {
  return (
    <div className="flex items-center h-full col-span-1 gap-4 p-3 rounded-lg md:p-5 bg-boxbg hover:bg-boxbg2">
      <div className="flex items-center justify-center w-16 h-16 bg-white rounded-full aspect-square">
        <img src={data.logoSrc} alt="" className="object-contain w-10" />
      </div>
      <div className="flex flex-col w-full gap-1">
        <p className="text-sm font-semibold md:text-lg">{data.text1}</p>
        <p className="text-xs md:text-sm">{data.text2}</p>
      </div>
    </div>
  );
}

export default CompanyGrid;
