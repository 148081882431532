import mslogo from "../assets/img/company/ms.png";
import yglogo from "../assets/img/company/yg_logo.svg";
import support from "../assets/img/company/support.png";
import change from "../assets/img/company/change.png";
import worth from "../assets/img/company/worth.png";
import teach from "../assets/img/company/teach.png";

const companyData = [
  {
    logoSrc: mslogo,
    text1: "코끼리메이커스페이스",
    text2: "다양한 제작 장비와 공간을 공유합니다.",
    path: "/makerspace1",
  },
  {
    logoSrc: yglogo,
    text1: "사회실험공간 여그",
    text2: "협업과 공유를 위한 플랫폼을 운영합니다.",
    path: "/yeogeu",
  },
  {
    logoSrc: support,
    text1: "로컬 창업 지원",
    text2: "메이커 협동조합, 로컬 비즈니스 등을 지원합니다.",
    path: "https://blog.naver.com/co77iri/223051497211",
  },
  {
    logoSrc: change,
    text1: "세상의 변화를 위한 기획",
    text2: "기술의 바른 쓰임을 통해 더 나은 세상을 위한 프로젝트를 운영합니다.",
    path: "/work1",
  },
  {
    logoSrc: worth,
    text1: "메이커 프로젝트",
    text2:
      "발상의 전환을 통한 제작 실험 및 제/작품을 만들고 찾아가는 메이커 교육, 메이커를 위한 환경 등을 구축합니다.",
    path: "/work2",
  },
  {
    logoSrc: teach,
    text1: "로컬 비즈니스",
    text2:
      "메이커 협동조합 등 창업을 지원하고 로컬 콘텐츠 발굴 및 로컬 비즈니스 생태계를 만듭니다.",
    path: "/work4",
  },
];

export default companyData;
