import React, { useEffect, useState } from "react";
import axios from "axios";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/autoplay";
import { Navigation, Pagination, Mousewheel, Keyboard, Autoplay } from "swiper/modules";

import Posts from "../pagination/Posts";
import PaginationComponent from "../pagination/PaginationComponent";

function Work2() {
  const currentCategory = "제작";

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPosts, setTotalPosts] = useState(0); // 전체 포스트 개수 상태
  const postsPerPage = 12; // 한 페이지에 표시할 포스트 수

  // 포스트 데이터 가져오기
  useEffect(() => {
    setLoading(true);
    axios
      .get(
        `https://co77iri.com/fetchArticles/category/${currentCategory}/${currentPage}`
      )
      .then((res) => {
        setData(res.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setLoading(false);
      });
  }, [currentPage, currentCategory]);

  // 전체 포스트 개수 가져오기
  useEffect(() => {
    axios
      .get(`https://co77iri.com/getTotalArticleNum/${currentCategory}`)
      .then((res) => {
        setTotalPosts(res.data.total);
      })
      .catch((error) =>
        console.error("Error fetching total article number:", error)
      );
  }, [currentCategory]); // currentCategory가 변경될 때만 실행

  // 전체 페이지 수 계산
  const totalPages = Math.ceil(totalPosts / postsPerPage);

  return (
    <div className="2xl:w-[60%] w-[90%] mx-auto md:mt-10 mt-5 flex flex-col md:gap-24 gap-12 leading-7">
      <Swiper
        // navigation={true} 화살표x
        pagination={{
          clickable: true,
        }}
        mousewheel={true}
        keyboard={true}
        autoplay={{
          delay: 5000,
          disableOnInteraction: false
        }}
        modules={[Navigation, Pagination, Mousewheel, Keyboard, Autoplay]}
        className="w-full h-full text-xl text-center rounded-lg cursor-pointer bg-boxbg"
      >
        <SwiperSlide className="px-10 pt-3 md:pt-4 pb-9 md:px-5">
          <p className="mt-2 text-base font-semibold text-center md:text-xl md:mt-0">
            ✨ 의미있는 제품 제작
          </p>
          <p className="text-sm text-center md:text-base">
            일상의 문제 해결을 위한 제품, 이동권이나 환경 등 사회적인 의미를
            가진 제품 등
            <br />
            특별한 의미를 가진 제품을 제작하고 있습니다.
          </p>
        </SwiperSlide>
        <SwiperSlide className="px-10 pt-3 md:pt-4 pb-9 md:px-5">
          <p className="mt-2 text-base font-semibold text-center md:text-xl md:mt-0">
            🛠️ 워크숍 및 메이커톤 개최
          </p>
          <p className="text-sm text-center md:text-base">
            정기적으로 지역 주민, 메이커를 대상으로 다양한 워크숍과 메이커톤을
            개최합니다.
            <br />
            매번 새로운 주제로 다양한 프로그램을 준비하고 있습니다.
          </p>
        </SwiperSlide>
      </Swiper>

      <div className="grid grid-cols-1 gap-10 md:grid-cols-2 lg:grid-cols-3 md:gap-14">
        <Posts data={data} loading={loading} />
      </div>

      <PaginationComponent
        currentPage={currentPage}
        totalPages={totalPages}
        paginate={setCurrentPage}
      />
    </div>
  );
}

export default Work2;
