import saveTheChilderen from "../assets/img/carousel/saveTheChildren.png";
import bookVillage from "../assets/img/carousel/bookVillage.png";
import scienceMakerFestival from "../assets/img/carousel/scienceMakerFestival.png";
import treadmillGatcha from "../assets/img/carousel/treadmillGatcha.png"
import searoad from "../assets/img/carousel/1004Searoad.png";
import nightFestival from "../assets/img/carousel/nightFestival.png";
import fleaMarket from "../assets/img/carousel/fleaMarket.png";
import sinanMakerEdu from "../assets/img/carousel/sinanMakerEdu.png";
import backpackerMakerCamp from "../assets/img/carousel/backpackerMakerCamp.png";
import upcyclingHackerton from "../assets/img/carousel/upcyclingHackerton.png";
import beGlocalHero from "../assets/img/carousel/beGlocalHero.png";
import lightFestival from "../assets/img/carousel/lightFestival.png";

const carouselData = [
  // {
  //   sort: "교육",
  //   date: "2019-12",
  //   title: (
  //     <>
  //       세이브더칠드런
  //       <br />
  //       찾아가는 메이커교육
  //     </>
  //   ),
  //   tag: "#교육 #워크숍 #제작",
  //   img: saveTheChilderen,
  //   link: "saveTheChildren"
  // },
  {
    sort: "제작",
    date: "2020-09",
    title: (
      <>
        고창 책마을해리
        <br />
        부엉이 만들기 프로젝트
      </>
    ),
    tag: "#제작 #목공",
    img: bookVillage,
    link: "bookVillage"
  },
  // {
  //   sort: "제작",
  //   date: "2021-06",
  //   title: (
  //     <>
  //       과학관과 메이커들의
  //       <br />
  //       기발한 전시 : 일상 무기
  //     </>
  //   ),
  //   tag: "#제작 #목공 #축제",
  //   img: scienceMakerFestival,
  //   link: "scienceMakerFestival"
  // },
  {
    sort: "제작",
    date: "2021-07",
    title: (
      <>
        첨단전환마을 에너지카페
        <br />
        트레드밀 뽑기머신 제작
      </>
    ),
    tag: "#제작 #목공 #에너지절약",
    img: treadmillGatcha,
    link: "https://youtu.be/1urfR7KihC4?si=MjM_GP-iR4oIejQY"
  },
  {
    sort: "기획",
    date: "2021-10",
    title: (
      <>
        섬 주민의 이동권을 위한
        <br />
        신안군 여객선 운항 정보 시스템 구축
      </>
    ),
    tag: "#기획 #로컬 #이동권",
    img: searoad,
    link: "https://searoad.co77iri.com"
    // 링크를 searoad.co77iri.com으로 걸기
  },
  {
    sort: "교육",
    date: "2022-07",
    title: (
      <>
        신안군 초등학생들과 함께한
        <br />
        찾아가는 메이커 교육
      </>
    ),
    tag: "#교육 #워크숍 #신안군",
    img: sinanMakerEdu,
    link: "sinanMakerEdu"
  },
  {
    sort: "기획",
    date: "2022-09",
    title: (
      <>
        청년과 음악, 예술 상인이 함께하는
        <br />
        구시청 나이트 페스티벌
      </>
    ),
    tag: "#제작 #메이커스페이스 #교육",
    img: nightFestival,
    link: "nightFestival"
  },
  {
    sort: "로컬",
    date: "2022-09",
    title: (
      <>
        청년 창업가와 지역주민을 위한
        <br />
        오월첫동네 플리마켓
      </>
    ),
    tag: "#제작 #메이커스페이스 #교육", //
    img: fleaMarket, //
    link: "fleaMarket"
  },
  {
    sort: "기획",
    date: "2022-11",
    title: (
      <>
        백패커, 메이커, 신안군 주민이 모인
        <br />
        백패커와 함께하는 메이커 캠프
      </>
    ),
    tag: "#기획 #축제 #제작",
    img: backpackerMakerCamp,
    link: "https://youtu.be/TxwjuPWP8aw?si=AuGfcihUKYzAV_9N"
  },
  {
    sort: "제작",
    date: "2022-12",
    title: (
      <>
        폐가구를 활용한 업사이클링 해커톤
        <br />
        오늘의 집, 내일의 삶
      </>
    ),
    tag: "#제작 #목공 #업사이클링",
    img: upcyclingHackerton,
    link: "https://youtu.be/u1AnyMYSPqo?si=FgYsvuAuNfkOxfUH"
  },
  {
    sort: "로컬",
    date: "2023-05",
    title: (
      <>
        로컬 기업을 글로벌까지
        <br />
        Be 글로컬 히어로
      </>
    ),
    tag: "#로컬 #IR투자 #멘토링",
    img: beGlocalHero,
    link: "beGlocalHero"
  },
  {
    sort: "기획",
    date: "2023-12",
    title: (
      <>
        충장금남로의 겨울밤을 환하게
        <br />
        크리스마스n광주빛축제
      </>
    ),
    tag: "#기획 #로컬 #축제",
    img: lightFestival,
    link: "lightFestival"
  },
];

export default carouselData;
