import React from "react";
import Card from "./Card";
import carouselData from "../../data/carouselData";

// 스와이퍼
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/autoplay";
import {
  Navigation,
  Pagination,
  Mousewheel,
  Keyboard,
  Autoplay,
} from "swiper/modules";
import "../../assets/styles/swiper.css";

function SwiperComponent() {
  return (
    <>
      <div className="2xl:w-[60%] w-[90%] mx-auto md:mt-10 mt-5">
        <p className="text-lg font-semibold md:text-2xl">
          🎞 코끼리협동조합이 쌓아온 이야기
        </p>
        <Swiper
          navigation={true}
          pagination={{
            clickable: true,
          }}
          spaceBetween={30}
          slidesPerView={3}
          slideToClickedSlide={true}
          mousewheel={true}
          keyboard={true}
          autoplay={{
            delay: 5000,
            disableOnInteraction: false,
          }}
          modules={[Navigation, Pagination, Mousewheel, Keyboard, Autoplay]}
          breakpoints={{
            320: {
              slidesPerView: 1,
            },
            768: {
              slidesPerView: 2,
            },
            1024: {
              slidesPerView: 3,
            },
          }}
          className="w-full h-full mt-5 text-xl text-center rounded-lg cursor-pointer slider"
        >
          {carouselData.map((item, index) => (
            <SwiperSlide className="rounded-lg bg-boxbg hover:bg-boxbg2">
              <Card key={index} data={item} />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>

      <div className="md:mt-20 mt-16 2xl:w-[60%] w-[90%] mx-auto border-b"></div>
    </>
  );
}

export default SwiperComponent;
