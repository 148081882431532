import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import logo from "../../assets/img/navbar/logo.svg";
function Navbar() {
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();

  return (
    <div className="flex md:flex-col items-center md:items-stretch flex-row justify-between pt-10 md:pb-10 pb-5 border-b 2xl:w-[60%] w-[90%] mx-auto md:px-0 px-3">
      <Link to="/">
        <img src={logo} alt="logo" className="h-8 mb-0 md:mb-5" />
      </Link>
      {/* nav1 */}
      <div className="justify-end hidden gap-3 text-sm md:flex">
        <Link
          to="/company"
          className={`px-3 py-1 ${
            location.pathname === "/company"
              ? "font-semibold bg-gray-950 text-white rounded-full"
              : "hover:font-semibold"
          }`}
        >
          <p>회사소개</p>
        </Link>
        <Link
          to="/makerspace1"
          className={`px-3 py-1 relative group ${
            location.pathname === "/makerspace1" ||
            location.pathname === "/makerspace2"
              ? "font-semibold bg-gray-950 text-white rounded-full"
              : "hover:font-semibold"
          }`}
        >
          <p>메이커스페이스</p>
          <div className="absolute flex flex-col invisible mt-1 text-sm font-normal text-center hover:font-normal w-36 group-hover:visible">
            <div className="h-1 bg-white"></div>
            <div className="text-white bg-gray-700 rounded-lg">
              <Link
                to="/makerspace1"
                className={`flex justify-center py-2 rounded-t-lg hover:bg-gray-950 ${
                  location.pathname === "/makerspace1" ? "bg-gray-950" : ""
                }`}
              >
                <p>메이커스페이스 소개</p>
              </Link>
              <Link
                to="/makerspace2"
                className={`flex justify-center py-2 rounded-b-lg hover:bg-gray-950 ${
                  location.pathname === "/makerspace2" ? "bg-gray-950" : ""
                }`}
              >
                <p>장비 소개</p>
              </Link>
            </div>
          </div>
        </Link>
        <p className="px-3 py-1">|</p>
        <Link
          to="/work1"
          className={`px-3 py-1 ${
            location.pathname === "/work1"
              ? "font-semibold bg-gray-950 text-white rounded-full"
              : "hover:font-semibold"
          }`}
        >
          <p>기획</p>
        </Link>
        <Link
          to="/work2"
          className={`px-3 py-1 ${
            location.pathname === "/work2"
              ? "font-semibold bg-gray-950 text-white rounded-full"
              : "hover:font-semibold"
          }`}
        >
          <p>제작</p>
        </Link>
        <Link
          to="/work3"
          className={`px-3 py-1 ${
            location.pathname === "/work3"
              ? "font-semibold bg-gray-950 text-white rounded-full"
              : "hover:font-semibold"
          }`}
        >
          <p>교육</p>
        </Link>
        <Link
          to="/work4"
          className={`px-3 py-1 ${
            location.pathname === "/work4"
              ? "font-semibold bg-gray-950 text-white rounded-full"
              : "hover:font-semibold"
          }`}
        >
          <p>로컬</p>
        </Link>
      </div>

      {/* nav2 - 모바일 */}
      <div className="block md:hidden">
        <button
          onClick={() => setIsOpen(!isOpen)}
          className="flex items-center rounded hover:text-black-400"
        >
          <svg
            className={`fill-current md:h-5 h-3 md:w-5 w-3 ${
              isOpen ? "hidden" : "block"
            }`}
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
          </svg>
          <svg
            className={`fill-current md:h-5 h-3 md:w-5 w-3 ${
              isOpen ? "block" : "hidden"
            }`}
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M10 8.586L2.929 1.515 1.515 2.929 8.586 10l-7.071 7.071 1.414 1.414L10 11.414l7.071 7.071 1.414-1.414L11.414 10l7.071-7.071-1.414-1.414L10 8.586z" />
          </svg>
        </button>
      </div>
      {isOpen && (
        <div className="z-50 absolute bg-white left-0 right-0 w-full border-b border-[#E9E9E9] p-0 top-24">
          <div className="flex flex-col h-full gap-3 px-10 py-5 text-sm">
            <Link
              to="/company"
              className={
                location.pathname === "/company" ? "font-semibold" : ""
              }
            >
              <p>회사소개</p>
            </Link>
            <div className="flex flex-col gap-2">
              <Link
                to="/makerspace1"
                className={
                  location.pathname === "/makerspace1" ||
                  location.pathname === "/makerspace2"
                    ? "font-semibold"
                    : ""
                }
              >
                <p>메이커스페이스</p>
              </Link>
              <div className="flex flex-col gap-1 px-3 text-xs text-gray-500">
                <Link
                  to="/makerspace1"
                  className={
                    location.pathname === "/makerspace1" ? "font-semibold" : ""
                  }
                >
                  <p className="">· 메이커스페이스 소개</p>
                </Link>
                <Link
                  to="/makerspace2"
                  className={
                    location.pathname === "/makerspace2" ? "font-semibold" : ""
                  }
                >
                  <p>· 장비 소개</p>
                </Link>
              </div>
            </div>

            <Link
              to="/work1"
              className={location.pathname === "/work1" ? "font-semibold" : ""}
            >
              <p>기획</p>
            </Link>
            <Link
              to="/work2"
              className={location.pathname === "/work2" ? "font-semibold" : ""}
            >
              <p>제작</p>
            </Link>
            <Link
              to="/work3"
              className={location.pathname === "/work3" ? "font-semibold" : ""}
            >
              <p>교육</p>
            </Link>
            <Link
              to="/work4"
              className={location.pathname === "/work4" ? "font-semibold" : ""}
            >
              <p>로컬</p>
            </Link>
          </div>
        </div>
      )}
    </div>
  );
}

export default Navbar;
