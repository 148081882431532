import React from "react";
import { Link } from "react-router-dom";

function Card({ data }) {
  // 외부 링크인지 확인. 외부 링크이면 <a> 태그 사용, 내부 링크이면 <Link> 사용.
  const isExternalLink = data.link && data.link.startsWith("http");

  return (
    <>
      {isExternalLink ? (
        // 외부 링크 처리
        <a href={data.link} target="_blank" rel="noopener noreferrer">
          <CardComponent data={data} />
        </a>
      ) : (
        // 내부 라우팅 처리
        <Link to={`/story/${data.link}`}>
          <CardComponent data={data} />
        </Link>
      )}
    </>
  );
}

function CardComponent({ data }) {
  return (
    <li className="rounded-lg cursor-pointer bg-boxbg hover:bg-boxbg2 lg:h-96 md:h-80 h-60 lg:w-80 md:w-64 w-52">
      <div className="flex flex-col justify-between p-3 md:p-5 h-3/5">
        <div className="flex flex-col gap-2 lg:gap-5">
          <p className="flex items-center justify-center w-1/3 py-1 text-sm font-semibold bg-white rounded-full md:w-1/4 md:text-base lg:w-1/5 ">
            {data.sort}
          </p>
          <p className="text-base font-semibold md:text-md lg:text-lg">
            {data.title}
          </p>
        </div>
        <div className="flex gap-2 text-xs text-gray-500 md:text-base">
          <p>{data.tag}</p>
        </div>
      </div>
      <div className="bg-[#A3A3A3] rounded-b-lg h-2/5 flex w-full">
        <img
          src={data.img}
          alt=""
          className="object-cover w-full rounded-b-lg"
        />
      </div>
    </li>
  );
}

export default Card;
