import React from "react";
import { Link } from "react-router-dom";
import kakao from "../../../assets/img/carousel/story/kakao.png";
import link from "../../../assets/img/carousel/story/link.png";

// ot 1~4번
import img1 from "../../../assets/img/carousel/beglocalhero/1.jpg";
import img2 from "../../../assets/img/carousel/beglocalhero/2.jpg";
import img3 from "../../../assets/img/carousel/beglocalhero/3.jpg";
import img4 from "../../../assets/img/carousel/beglocalhero/4.jpg";
// 예선 5번~
import img5 from "../../../assets/img/carousel/beglocalhero/5.JPG";
import img6 from "../../../assets/img/carousel/beglocalhero/6.JPG";
import img7 from "../../../assets/img/carousel/beglocalhero/7.JPG";
import img8 from "../../../assets/img/carousel/beglocalhero/8.JPG";
import img9 from "../../../assets/img/carousel/beglocalhero/9.JPG";
import img10 from "../../../assets/img/carousel/beglocalhero/10.JPG";
import img11 from "../../../assets/img/carousel/beglocalhero/11.JPG";
import img12 from "../../../assets/img/carousel/beglocalhero/12.JPG";
import img13 from "../../../assets/img/carousel/beglocalhero/13.JPG";
import img14 from "../../../assets/img/carousel/beglocalhero/14.JPG";
import img15 from "../../../assets/img/carousel/beglocalhero/15.JPG";
import img16 from "../../../assets/img/carousel/beglocalhero/16.JPG";
import img17 from "../../../assets/img/carousel/beglocalhero/17.JPG";

function BeGlocalHero() {
  return (
    <div className="2xl:w-[60%] w-[90%] mx-auto md:mt-10 mt-5 flex flex-col md:gap-32 gap-20">
      {/* 상단 */}
      <div className="flex flex-col items-center gap-5 md:gap-10">
        <div className="flex text-sm md:text-base lg:text-lg">
          <p>제작</p>
          <p>&nbsp;|&nbsp;</p>
          <p>2023-05</p>
        </div>
        <p className="flex flex-col gap-0 text-lg font-semibold text-center md:gap-2 md:text-2xl lg:text-4xl">
          <p>로컬 기업을 글로벌까지</p>
          <p>Be 글로컬 히어로</p>
        </p>
        {/* <div className="flex gap-3 mt-1 md:mt-3">
          <div className="w-8 p-2 rounded-full md:w-10 bg-boxbg hover:bg-boxbg2">
            <img src={kakao} alt="" className="object-cover" />
          </div>
          <div className="w-8 p-2 rounded-full md:w-10 bg-boxbg hover:bg-boxbg2">
            <img src={link} alt="" className="object-cover" />
          </div>
        </div> */}
      </div>
      {/* 내용 */}
      <div className="flex flex-col items-center gap-5 text-center md:gap-10">
        <div className="flex flex-col gap-1 md:gap-3">
          <div className="flex w-full">
            <img
              className="object-cover w-full aspect-video"
              src={img1}
              alt=""
            />
          </div>
          <div className="flex w-full">
            <img
              className="object-cover w-full aspect-video"
              src={img2}
              alt=""
            />
          </div>
          <div className="flex w-full">
            <img
              className="object-cover w-full aspect-video"
              src={img3}
              alt=""
            />
          </div>
          <div className="flex w-full">
            <img
              className="object-cover w-full aspect-video"
              src={img4}
              alt=""
            />
          </div>
          <p className="text-sm md:text-lg">2023-05-12 글로컬 OT</p>
        </div>
        {/* -- */}
        <div className="flex flex-col gap-1 md:gap-3">
          <div className="flex w-full">
            <img
              className="object-cover w-full aspect-video"
              src={img5}
              alt=""
            />
          </div>
          <div className="flex w-full">
            <img
              className="object-cover w-full aspect-video"
              src={img6}
              alt=""
            />
          </div>
          <div className="flex w-full">
            <img
              className="object-cover w-full aspect-video"
              src={img7}
              alt=""
            />
          </div>
          <div className="flex w-full">
            <img
              className="object-cover w-full aspect-video"
              src={img8}
              alt=""
            />
          </div>
          <div className="flex w-full">
            <img
              className="object-cover w-full aspect-video"
              src={img9}
              alt=""
            />
          </div>
          <div className="flex w-full">
            <img
              className="object-cover w-full aspect-video"
              src={img10}
              alt=""
            />
          </div>
          <div className="flex w-full">
            <img
              className="object-cover w-full aspect-video"
              src={img11}
              alt=""
            />
          </div>
          <div className="flex w-full">
            <img
              className="object-cover w-full aspect-video"
              src={img12}
              alt=""
            />
          </div>
          <div className="flex w-full">
            <img
              className="object-cover w-full aspect-video"
              src={img13}
              alt=""
            />
          </div>
          <div className="flex w-full">
            <img
              className="object-cover w-full aspect-video"
              src={img14}
              alt=""
            />
          </div>
          <div className="flex w-full">
            <img
              className="object-cover w-full aspect-video"
              src={img15}
              alt=""
            />
          </div>
          <div className="flex w-full">
            <img
              className="object-cover w-full aspect-video"
              src={img16}
              alt=""
            />
          </div>
          <div className="flex w-full">
            <img
              className="object-cover w-full aspect-video"
              src={img17}
              alt=""
            />
          </div>
          <p className="text-sm md:text-lg">2023-06-02 글로컬 예선</p>
        </div>
      </div>
      {/* 하단 */}
      <div className="flex flex-col items-center gap-1 md:gap-3">
        {/* <div className="flex gap-3">
          <div className="w-8 p-2 rounded-full md:w-10 bg-boxbg hover:bg-boxbg2">
            <img src={kakao} alt="" className="object-cover" />
          </div>
          <div className="w-8 p-2 rounded-full md:w-10 bg-boxbg hover:bg-boxbg2">
            <img src={link} alt="" className="object-cover" />
          </div>
        </div> */}
        <Link to="/">
          <button className="h-8 mt-3 text-base rounded-full w-28 md:h-10 bg-boxbg hover:bg-boxbg2">
            이전으로
          </button>
        </Link>
      </div>
    </div>
  );
}

export default BeGlocalHero;
