function YeogeuCard({ text, image }) {
  return (
    <div className="relative w-full col-span-1 ">
      <div className="w-full border rounded-lg cursor-pointer border-boxstroke aspect-video group">
        <img
          src={image}
          alt=""
          className="object-cover rounded-lg aspect-video"
        />
        <p className="absolute top-0 items-center justify-center visible hidden w-full h-full text-xl text-white rounded-lg lg:flex group-hover:invisible backdrop-brightness-50">
          {text}
        </p>
      </div>
      <p className="flex justify-center w-full text-sm lg:hidden">{text}</p>
    </div>
  );
}

export default YeogeuCard;
