import React from "react";
import { Link } from "react-router-dom";

import fb from "../../assets/img/footer/fb_logo.png";
import ig from "../../assets/img/footer/ig_logo.png";
import yt from "../../assets/img/footer/yt_logo.png";
import nv from "../../assets/img/footer/nv_logo.png";

function Footer() {
  return (
    <div className="flex md:flex-row flex-col justify-between md:mt-10 mt-5 md:pb-20 pb-10 md:pt-10 pt-5 border-t 2xl:w-[60%] w-[90%] mx-auto md:px-0 px-3">
      <div className="flex flex-col text-xs md:text-base opacity-60">
        <p>광주 광역시 북구 무등로 190</p>
        <p>062-513-2014</p>
        <p>co77iri@gmail.com</p>
      </div>
      <div className="flex items-center justify-end gap-4 mt-5 md:gap-5 md:justify-center md:mt-0">
        <Link to={"https://www.facebook.com/co77iri/"}>
          <img
            src={fb}
            alt=""
            className="w-4 h-4 md:w-6 md:h-6 opacity-60 hover:opacity-80"
          />
        </Link>
        <Link to={"https://www.instagram.com/co77iri/"}>
          <img
            src={ig}
            alt=""
            className="w-4 h-4 md:w-6 md:h-6 opacity-60 hover:opacity-80"
          />
        </Link>
        <Link to={"https://www.youtube.com/@user-io8dn8ms1g/featured"}>
          <img
            src={yt}
            alt=""
            className="w-4 h-4 md:w-6 md:h-6 opacity-60 hover:opacity-80"
          />
        </Link>
        <Link to={"https://blog.naver.com/co77iri"}>
          <img
            src={nv}
            alt=""
            className="w-4 h-4 md:w-6 md:h-6 opacity-60 hover:opacity-80"
          />
        </Link>
      </div>
    </div>
  );
}

export default Footer;
