import React from "react";
import { Link } from "react-router-dom";
import kakao from "../../../assets/img/carousel/story/kakao.png";
import link from "../../../assets/img/carousel/story/link.png";

// 거리 점등식
import img1 from "../../../assets/img/carousel/lightfestival/1.jpg";
import img2 from "../../../assets/img/carousel/lightfestival/2.jpg";

// 구시청 조명
import img3 from "../../../assets/img/carousel/lightfestival/3.jpg";
import img4 from "../../../assets/img/carousel/lightfestival/4.jpg";
import img5 from "../../../assets/img/carousel/lightfestival/5.jpg";

// 폴리 공연
import img6 from "../../../assets/img/carousel/lightfestival/6.jpg";
import img7 from "../../../assets/img/carousel/lightfestival/7.jpg";

// 선물박스
import img8 from "../../../assets/img/carousel/lightfestival/8.jpg";
import img9 from "../../../assets/img/carousel/lightfestival/9.jpg";

// 뱅쇼
import img10 from "../../../assets/img/carousel/lightfestival/10.jpg";
import img11 from "../../../assets/img/carousel/lightfestival/11.jpg";

// 포토존 이벤트
import img12 from "../../../assets/img/carousel/lightfestival/12.jpg";
import img13 from "../../../assets/img/carousel/lightfestival/13.jpg";
import img14 from "../../../assets/img/carousel/lightfestival/14.jpg";

function LightFestival() {
  return (
    <div className="2xl:w-[60%] w-[90%] mx-auto md:mt-10 mt-5 flex flex-col md:gap-32 gap-20">
      {/* 상단 */}
      <div className="flex flex-col items-center gap-1 md:gap-3">
        <div className="flex text-sm md:text-base lg:text-lg">
          <p>제작</p>
          <p>&nbsp;|&nbsp;</p>
          <p>2023-12</p>
        </div>
        <p className="flex flex-col gap-1 text-lg font-semibold text-center md:text-2xl lg:text-4xl">
          <p>충장금남로의 겨울밤을 환하게</p>
          <p>크리스마스n광주빛축제</p>
        </p>
        {/* <div className="flex gap-3 mt-1 md:mt-3">
          <div className="w-8 p-2 rounded-full md:w-10 bg-boxbg hover:bg-boxbg2">
            <img src={kakao} alt="" className="object-cover" />
          </div>
          <div className="w-8 p-2 rounded-full md:w-10 bg-boxbg hover:bg-boxbg2">
            <img src={link} alt="" className="object-cover" />
          </div>
        </div> */}
      </div>
      {/* 내용 */}
      <div className="flex flex-col items-center gap-5 text-center md:gap-10">
        <div className="flex flex-col gap-1 md:gap-3">
          <div className="flex w-full">
            <img
              className="object-cover w-full aspect-video"
              src={img1}
              alt=""
            />
          </div>
          <div className="flex w-full">
            <img
              className="object-cover w-full aspect-video"
              src={img2}
              alt=""
            />
          </div>
          <p className="text-sm md:text-lg">거리 점등식</p>
        </div>
        {/* -- */}
        <div className="flex flex-col gap-1 md:gap-3">
          <div className="flex w-full">
            <img
              className="object-cover w-full aspect-video"
              src={img3}
              alt=""
            />
          </div>
          <div className="flex w-full">
            <img
              className="object-cover w-full aspect-video"
              src={img4}
              alt=""
            />
          </div>
          <div className="flex w-full">
            <img
              className="object-cover w-full aspect-video"
              src={img5}
              alt=""
            />
          </div>
          <p className="text-sm md:text-lg">구시청 조명</p>
        </div>
        {/* -- */}
        <div className="flex flex-col gap-1 md:gap-3">
          <div className="flex w-full">
            <img
              className="object-cover w-full aspect-video"
              src={img6}
              alt=""
            />
          </div>
          <div className="flex w-full">
            <img
              className="object-cover w-full aspect-video"
              src={img7}
              alt=""
            />
          </div>
          <p className="text-sm md:text-lg">폴리 공연</p>
        </div>
        {/* -- */}
        <div className="flex flex-col gap-1 md:gap-3">
          <div className="flex w-full">
            <img
              className="object-cover w-full aspect-video"
              src={img8}
              alt=""
            />
          </div>
          <div className="flex w-full">
            <img
              className="object-cover w-full aspect-video"
              src={img9}
              alt=""
            />
          </div>
          <p className="text-sm md:text-lg">선물박스</p>
        </div>
        {/* -- */}
        <div className="flex flex-col gap-1 md:gap-3">
          <div className="flex w-full">
            <img
              className="object-cover w-full aspect-video"
              src={img10}
              alt=""
            />
          </div>
          <div className="flex w-full">
            <img
              className="object-cover w-full aspect-video"
              src={img11}
              alt=""
            />
          </div>
          <p className="text-sm md:text-lg">뱅쇼</p>
        </div>
        {/* -- */}
        <div className="flex flex-col gap-1 md:gap-3">
          <div className="flex w-full">
            <img
              className="object-cover w-full aspect-video"
              src={img12}
              alt=""
            />
          </div>
          <div className="flex w-full">
            <img
              className="object-cover w-full aspect-video"
              src={img13}
              alt=""
            />
          </div>
          <div className="flex w-full">
            <img
              className="object-cover w-full aspect-video"
              src={img14}
              alt=""
            />
          </div>
          <p className="text-sm md:text-lg">포토존 이벤트</p>
        </div>
      </div>
      {/* 하단 */}
      <div className="flex flex-col items-center gap-1 md:gap-3">
        {/* <div className="flex gap-3">
          <div className="w-8 p-2 rounded-full md:w-10 bg-boxbg hover:bg-boxbg2">
            <img src={kakao} alt="" className="object-cover" />
          </div>
          <div className="w-8 p-2 rounded-full md:w-10 bg-boxbg hover:bg-boxbg2">
            <img src={link} alt="" className="object-cover" />
          </div>
        </div> */}
        <Link to="/">
          <button className="h-8 mt-3 text-base rounded-full w-28 md:h-10 bg-boxbg hover:bg-boxbg2">
            이전으로
          </button>
        </Link>
      </div>
    </div>
  );
}

export default LightFestival;
