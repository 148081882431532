import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";

function Admin() {
  const [id, setId] = useState("");
  const [password, setPassword] = useState("");

  const onIdHandler = (event) => {
    setId(event.currentTarget.value);
  };

  const onPasswordHandler = (event) => {
    setPassword(event.currentTarget.value);
  };

  const navigate = useNavigate();
  const onSubmitHandler = async (event) => {
    event.preventDefault();
    try {
      const response = await axios.post("http://localhost:8800/adminLogin", {
        id: id,
        password: password,
      });
      console.log("서버에서 받은 토큰", response.data);

      // 토큰을 쿠키에 저장
      Cookies.set("token", response.data.token, { expires: 1 });

      // 로그인 성공, token 받았으면 "/adminadd" 페이지로 이동
      navigate("/adminadd");
    } catch (error) {
      console.error("서버로 로그인 정보 전송 실패", error.response?.data);
      alert("로그인 실패");
    }
  };

  return (
    <div className="2xl:w-[60%] w-[90%] mx-auto">
      <div className="flex flex-col items-center justify-center h-screen">
        <p className="mb-16 text-4xl font-semibold">관리자 페이지</p>
        <form
          className="flex flex-col w-full gap-10 text-base sm:w-80"
          onSubmit={onSubmitHandler}
        >
          <input
            type="text"
            placeholder="아이디"
            className="p-2 border"
            name="id"
            value={id}
            onChange={onIdHandler}
          />
          <input
            type="password"
            placeholder="비밀번호"
            className="p-2 border"
            name="password"
            value={password}
            onChange={onPasswordHandler}
          />
          <button className="p-2 font-semibold bg-orange-400 hover:bg-orange-200">
            로그인
          </button>
        </form>
      </div>
    </div>
  );
}

export default Admin;
